export const joinClasses = (...classes) => {
  return classes
    .filter((val) => val) // Removes false values from i18n.language === code
    .map((val) => val.toString()) // Ensure the values are strings
    .join(" ")
}

export const loadData = (arr, count, setCurrIndex) => {
  const data = arr.slice(0, count)
  const allDataLoaded = count >= arr.length

  const loadMoreData = () => {
    if (!allDataLoaded) {
      setCurrIndex(count)
    }
  }

  return { data, allDataLoaded, loadMoreData }
}
