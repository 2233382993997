import { FaCircle } from "react-icons/fa6"
import { joinClasses } from "../utils/utils"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { vscDarkPlus as codeTheme } from "react-syntax-highlighter/dist/esm/styles/prism"

const CodeSnippet = ({ className, code, lang }) => (
  <div className={joinClasses("shadow shadow-neutral-700 rounded-lg overflow-hidden", className)}>
    <div className="flex justify-start items-center space-x-1 px-2 bg-neutral-500 dark:bg-neutral-600 h-5 text-[0.6rem]">
      <FaCircle className="text-red-500 lg:text-red-200 hover:text-red-500" />
      <FaCircle className="text-yellow-500 lg:text-yellow-200 hover:text-yellow-500" />
      <FaCircle className="text-green-500 lg:text-green-200 hover:text-green-500" />
    </div>
    <div className="font-mono text-start">
      <SyntaxHighlighter
        language={lang}
        style={codeTheme}
        customStyle={{ padding: 20, margin: 0, minHeight: "17.5rem", width: "100%" }}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  </div>
)

export default CodeSnippet
