import Skills from "./Skills"
import fotoAlejandro from "../assets/img/foto_alejandro.jpg"
import { useTranslation } from "react-i18next"
import { FaDownload, FaRegCircleCheck } from "react-icons/fa6"

const Cover = () => {
  const { t, i18n } = useTranslation()
  const [title_a, title_b] = t("start.title").split(/\|/g)

  const bgCommon = "absolute w-5/6 h-4/5 bg-gradient-to-br blur-2xl rounded-[20%]"

  return (
    <div className="h-screen flex items-center justify-center relative font-raleway">
      <div
        className={`${bgCommon} from-neutral-50/0 dark:from-neutral-800/0 from-0% via-orange-100 dark:via-yellow-700/15 to-neutral-50/0 dark:to-neutral-800/0 to-60%`}
      />
      <div
        className={`${bgCommon} from-neutral-50/0 dark:from-neutral-800/0 from-40% via-red-100 dark:via-red-800/15 to-neutral-50/0 dark:to-neutral-800/0 to-100%`}
      />
      <div
        className={`${bgCommon} from-neutral-50/0 dark:from-neutral-800/0 from-20% via-slate-300 dark:via-slate-800 to-neutral-50/0 dark:to-neutral-800/0 to-80%`}
      />
      <div className="lg:absolute text-l md:text-xl xl:text-2xl flex flex-col justify-center items-center z-10">
        {/* Left Pole */}
        <div className="hidden lg:block absolute border-4 xl:border-8 border-orange-350 dark:border-orange-500 rounded-full -left-[5.5rem] xl:-left-30 top-[24.9rem] xl:top-[27.5rem] h-20 xl:h-28 z-10 dark:grayscale-[30%]" />
        {/* Right Pole */}
        <div className="hidden lg:block absolute border-4 xl:border-8 border-orange-350 dark:border-orange-500 rounded-b-full -right-24 xl:-right-32 top-8 h-[27.9rem] xl:h-[32.5rem] z-10 dark:grayscale-[30%]" />
        {/* Picture */}
        <div className="mt-24 md:mt-0 lg:absolute h-36 w-36 md:h-60 md:w-60 xl:h-72 xl:w-72 lg:-right-52 xl:-right-[16.5rem] lg:-top-48 xl:-top-52 border-[0.5rem] xl:border-[1rem] border-orange-350 dark:border-orange-500 rounded-full z-20 overflow-hidden dark:grayscale-[30%]">
          <img src={fotoAlejandro} alt={t("cover.fotoAlejandroAlt")} className="object-cover object-top" />
        </div>
        {/* Text */}
        <h1 className="max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl text-4xl md:text-5xl xl:text-7xl text-center mt-12 mb-6">
          {title_a}
          <span className="font-bold bg-clip-text bg-gradient-to-b from-orange-350 dark:from-orange-350 to-orange-600 dark:to-orange-700 text-transparent dark:grayscale-20%]">
            {title_b}
          </span>
        </h1>
        <div className="text-center italic mt-2 mb-4 text-slate-700 dark:text-slate-400 text-nowrap">
          {t("start.subtitle")
            .split(/\|/g)
            .map((text, i) => (
              <div key={i} className="flex space-x-3 items-center justify-center">
                <FaRegCircleCheck className="text-green-600 text-xl" />
                <p>{text}</p>
              </div>
            ))}
        </div>
        <div className="md:mt-10 flex flex-col md:flex-row md:gap-x-5 justify-center items-center">
          <a
            href="#contact"
            className="text-slate-700 dark:text-slate-400 hover:font-bold hover:text-orange-400 dark:hover:text-orange-350"
          >
            {t("navbar.contact")}
          </a>
          <p className="hidden md:inline-flex">|</p>
          <a
            href={`cv-albotero-${i18n.language}.pdf`}
            className="text-slate-700 dark:text-slate-400 hover:font-bold hover:text-orange-400 dark:hover:text-orange-350 flex gap-x-1.5"
            target="_blank"
            rel="noreferrer"
          >
            <FaDownload className="mt-0.5" /> {t("navbar.cv")}
          </a>
        </div>
        <Skills />
      </div>
    </div>
  )
}

export default Cover
