import React from "react"
import { useTranslation } from "react-i18next"
import { BsMoonStarsFill } from "react-icons/bs"
import { BsFillSunFill } from "react-icons/bs"

const ThemeSwitcher = ({ darkSide, toggleDarkMode }) => {
  const { t } = useTranslation()
  return (
    <div className="w-7 h-7 flex items-center hover:text-orange-350 dark:hover:text-orange-350">
      {darkSide ? (
        <BsMoonStarsFill className={`w-5 h-5 cursor-pointer`} onClick={toggleDarkMode} title={t("navbar.lightMode")} />
      ) : (
        <BsFillSunFill className={`w-6 h-6 cursor-pointer`} onClick={toggleDarkMode} title={t("navbar.darkMode")} />
      )}
    </div>
  )
}

export default ThemeSwitcher
