import { BACKEND_SERVER } from "../data/constants"
import { joinClasses } from "../utils/utils.js"
import { useTranslation } from "react-i18next"
import { BiSolidErrorAlt } from "react-icons/bi"
import { FaCircle, FaGithub, FaLinkedin, FaWhatsapp } from "react-icons/fa6"
import { IoCloudDone } from "react-icons/io5"
import { MdEmail } from "react-icons/md"
import { TiArrowBackOutline } from "react-icons/ti"
import { useState } from "react"

/* Obtained from https://github.com/driaug/country-picker */
import { countries } from "../country-picker/lib/countries.ts"
import CountrySelector from "../country-picker/lib/selector.tsx"

const Contact = () => {
  const [country, setCountry] = useState("CO")
  const [isOpen, setIsOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [sendResult, setSendResult] = useState({})
  const [showResult, setShowResult] = useState(false)

  const { t } = useTranslation()

  const linkClass = "m-6 flex space-x-2 items-center hover:text-orange-350 hover:font-semibold"
  const inputClass =
    "px-4 py-2 leading-7 font-pt-sans text-lg rounded bg-transparent border-b-slate-100 border-b-2 text-slate-100 placeholder-zinc-300 dark:placeholder-zinc-400"

  const selectedCountry = countries.find((option) => option.value === country)

  const postJSON = async (url, data) => {
    try {
      const response = await fetch(url, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      if (response.ok) {
        setSendResult(response)
      } else {
        // Response is not 200, return an error
        const err = `${response.status} - ${response.statusText}`
        console.error("Error:", err)
        setSendResult({ error: true, errors: [err] })
      }
    } catch (err) {
      console.error("Error:", err)
      setSendResult({ error: true, errors: [`${err}`] })
    }
    setShowResult(true)
  }

  const sendMessage = async (e) => {
    // Avoid the page to reload
    e.preventDefault()
    // If it is already loading, do nothing else
    if (isSending) return
    // Button loading animation
    setIsSending(true)
    // Get the data and send the email
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData.entries())
    await postJSON(`${BACKEND_SERVER}/api/contact`, data)
  }

  const handleGoBackClick = () => {
    setIsSending(false)
    setSendResult({})
    setShowResult(false)
  }

  return (
    <div className="md:bg-gradient-to-t from-transparent to-slate-300 via-slate-300 dark:to-zinc-900 dark:via-zinc-900 from-60% via-60% pt-12 md:pt-32 flex flex-col flex-wrap md:flex-row justify-evenly md:mb-24 gap-y-6 md:gap-y-16">
      {/* Left Panel */}
      <div className="md:bg-sky-700 dark:md:bg-sky-900 md:text-slate-100 md:w-2/5 lg:w-2/6 xl:w-1/4 rounded-xl px-2 lg:px-4 xl:px-6 md:py-10 md:shadow-lg md:shadow-neutral-600">
        <h2 className="text-2xl xl:text-4xl text-center font-raleway md:font-semibold m-5">{t("contact.title")}</h2>
        <h3 className="text-md xl:text-xl mt-8 mb-4 flex gap-x-2 justify-center items-center">
          <FaCircle className="w-4 h-4 text-green-500 drop-shadow-[0_0_2px_var(--tw-shadow-color)] shadow-neutral-500 dark:shadow-neutral-400 rounded-full" />{" "}
          {t("contact.available")}
        </h3>
        <p className="mt-1 mb-8 mx-auto w-5/6 text-center italic md:text-neutral-200 leading-4">
          {" "}
          {t("contact.availableDesc")}.
        </p>

        <p className="mb-8 mx-3">{t("contact.desc")}.</p>
        <a href="mailto:alejandro@albotero.com" className={linkClass}>
          <MdEmail className="w-6 h-6" />
          <span>alejandro@albotero.com</span>
        </a>
        <a href="https://wa.me/573113066308" target="_blank" rel="noreferrer" className={linkClass}>
          <FaWhatsapp className="w-6 h-6" />
          <span>+(57) 3113066308</span>
        </a>
        <a href="https://www.linkedin.com/in/botdev92/" target="_blank" rel="noreferrer" className={linkClass}>
          <FaLinkedin className="w-6 h-6" />
          <span>botdev92</span>
        </a>
        <a href="https://www.github.com/albotero/" target="_blank" rel="noreferrer" className={linkClass}>
          <FaGithub className="w-6 h-6" />
          <span>albotero</span>
        </a>
      </div>
      {/* Contact Form */}
      <div className="bg-sky-700 dark:md:bg-sky-900 text-slate-100 w-full md:w-2/4 lg:w-2/4 mx-auto md:my-6 md:m-0 md:rounded-xl py-10 px-8 md:px-5 lg:px-0 md:shadow-lg md:shadow-neutral-600">
        {showResult ? (
          <div className="flex flex-col justify-center h-full text-3xl xl:text-4xl text-center font-raleway md:font-semibold">
            {sendResult?.error ? (
              <h2 className="flex justify-center items-center gap-x-2 text-red-400 text-shadow-sm shadow-neutral-700 dark:shadow-neutral-800">
                <BiSolidErrorAlt className="drop-shadow-[1px_1px_2px_var(--tw-shadow-color)]" />
                {t(`contact.form.errorTitle`)}
              </h2>
            ) : (
              <h2 className="flex justify-center items-center gap-x-2 text-green-400 text-shadow-sm shadow-neutral-700 dark:shadow-neutral-800">
                <IoCloudDone className="drop-shadow-[1px_1px_2px_var(--tw-shadow-color)]" />
                {t(`contact.form.successTitle`)}
              </h2>
            )}
            <p className="md:px-[7.5%] lg:px-[15%] mt-6 text-center text-lg">
              {t(`contact.form.${sendResult.error ? "error" : "success"}`)}
            </p>
            {sendResult?.errors?.map((err, i) => (
              <p className="md:px-[7.5%] lg:px-[15%] text-center text-lg text-red-300" key={i}>
                {err}
              </p>
            ))}
            <TiArrowBackOutline
              className="mx-auto mt-10 text-4xl hover:text-orange-350 cursor-pointer"
              onClick={handleGoBackClick}
            />
          </div>
        ) : (
          <form id="contact-form" onSubmit={sendMessage}>
            <h2 className="text-2xl xl:text-4xl text-center font-raleway md:font-semibold m-3">
              {t("contact.form.title")}
            </h2>
            <div className="md:px-[7.5%] lg:px-[15%] py-8 flex flex-col space-y-6">
              <p className="my-2">{t("contact.form.desc")}.</p>
              <input
                type="text"
                name="name"
                placeholder={t("contact.form.name") + " *"}
                className={inputClass}
                disabled={isSending ?? "disabled"}
                required
              />
              <input
                type="email"
                name="email"
                placeholder={t("contact.form.email") + " *"}
                className={inputClass}
                disabled={isSending ?? "disabled"}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder={t("contact.form.phone")}
                className={inputClass}
                disabled={isSending ?? "disabled"}
              />
              <div className="text-neutral-700">
                <CountrySelector
                  open={isOpen}
                  onToggle={() => setIsOpen(!isOpen)}
                  onChange={(val) => setCountry(val)}
                  selectedValue={selectedCountry}
                  disabled={isSending ?? "disabled"}
                />
                <input type="hidden" name="phoneCode" value={selectedCountry.phoneCode} />
                <input type="hidden" name="country" value={selectedCountry.title} />
              </div>
              <textarea
                name="message"
                placeholder={t("contact.form.message") + " . . ."}
                className={inputClass}
                disabled={isSending ?? "disabled"}
              />
              <button
                id="submit-button"
                type="submit"
                className={joinClasses(
                  "rounded-lg w-min self-center px-5 py-2 font-pt-sans text-lg",
                  !isSending &&
                    "border-2 border-slate-100 hover:border-orange-350 hover:text-orange-350 hover:font-semibold"
                )}
              >
                {isSending ? (
                  <div
                    className="mx-3 inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] text-orange-400"
                    role="status"
                  >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                ) : (
                  t("contact.form.send")
                )}
              </button>
            </div>
            <div id="submit-result" className="hidden my-2" />
          </form>
        )}
      </div>

      {/* Review Form
      <div className="bg-sky-700 dark:md:bg-sky-900 text-slate-100 w-3/4 md:w-2/4 lg:w-2/4 mx-auto my-6 md:m-0 rounded-xl py-10 px-14  md:px-5 lg:px-0 shadow-lg shadow-neutral-600">
        <h2 className="text-2xl xl:text-4xl text-center font-raleway md:font-semibold m-5">{t("contact.comment.title")}</h2>
      </div> */}
    </div>
  )
}

export default Contact
