import { joinClasses } from "../utils/utils"
import { FaPlus } from "react-icons/fa6"

const LoadMore = ({ action, hidden }) => (
  <div
    className={joinClasses(
      "text-xl md:text-2xl lg:text-4xl xl:text-6xl mx-auto my-8 rounded-full w-10 h-10 p-1 md:w-14 md:h-14 md:p-4 flex justify-center items-center cursor-pointer shadow-md bg-neutral-200 dark:bg-neutral-700 hover:bg-orange-400 dark:hover:bg-orange-400 active:text-orange-50 dark:active:text-orange-950",
      hidden && "hidden"
    )}
    onClick={action}
  >
    <FaPlus />
  </div>
)

export default LoadMore
