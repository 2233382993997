import { projects } from "../data/projects"
import { joinClasses, loadData } from "../utils/utils"
import CodeSnippet from "./CodeSnippet"
import LoadMore from "./LoadMore"
import Project from "./Project"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const Work = () => {
  const { t } = useTranslation(["translation", "projects", "codeSnippets"])
  const [currIndex, setCurrIndex] = useState(0)
  const { data, allDataLoaded, loadMoreData } = loadData(projects, currIndex + 2, setCurrIndex)

  const buttons = {
    moreInfo: t("work.moreInfo"),
    code: t("work.code"),
  }

  return (
    <>
      <h2 className="text-3xl md:text-4xl xl:text-5xl text-center font-raleway pt-16">
        {t("work.title")
          .split(/\|/g)
          .reduce((prev, curr, i) => [...prev, <br key={i} />, curr])}
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 max-w-7xl mx-auto">
        {data.map((p, i) => (
          <Project
            projectData={{
              ...p,
              shortDesc: t(`projects:${p.tag}.shortDesc`),
              desc: t(`projects:${p.tag}.desc`),
            }}
            buttons={buttons}
            key={p.tag}
          />
        ))}
      </div>
      <LoadMore action={loadMoreData} hidden={allDataLoaded} />
      <div className="bg-gradient-to-b from-transparent to-slate-300 dark:to-zinc-900 to-[5.5rem] pt-32">
        <div className="flex flex-col items-center justify-center gap-10 lg:gap-24">
          {Array(3)
            .fill()
            .map((_, i) => (
              <div
                key={i}
                className={joinClasses(
                  "flex flex-col-reverse gap-x-6 text-center w-10/12 md:w-3/4 max-w-[60rem]",
                  i % 2 === 0 ? "lg:flex-row-reverse lg:text-end" : "lg:flex-row lg:text-start"
                )}
              >
                <CodeSnippet
                  className="w-full lg:w-8/12 max-w-[30rem] mx-auto"
                  lang={t(`codeSnippets:${i}.lang`)}
                  code={t(`codeSnippets:${i}.code`)}
                />
                <div className="my-4 lg:w-4/12 xl:w-5/12">
                  <h3 className="text-2xl lg:text-3xl xl:text-4xl font-raleway pt-8 lg:pb-4 font-semibold bg-clip-text bg-gradient-to-b from-rose-200 dark:from-rose-500 to-orange-800 dark:to-orange-900 text-transparent dark:grayscale-20%]">
                    {t(`codeSnippets:${i}.title`)}
                  </h3>
                  {t(`codeSnippets:${i}.desc`)
                    .split(/\|/g)
                    .map((el, j) => (
                      <p className="text-lg lg:text-xl xl:text-2xl font-pt-sans py-1 lg:py-2.5" key={j}>
                        {el}
                      </p>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default Work
