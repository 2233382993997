import useLocale from "../hooks/useLocale"
import { CircleFlag } from "react-circle-flags"
import { useTranslation } from "react-i18next"

const LangSwitcher = () => {
  const { language, changeLanguage } = useLocale()
  const { t } = useTranslation()

  return (
    <CircleFlag
      countryCode={language.flag}
      title={t("changeLanguage")}
      className="w-6 h-6 rounded-full object-cover cursor-pointer select-none hover:saturate-150"
      onClick={changeLanguage}
    />
  )
}

export default LangSwitcher
