import { sections } from "./App"
import LangSwitcher from "./LangSwitcher"
import ThemeSwitcher from "./ThemeSwitcher"
import logo from "../assets/img/logo.svg"
import logoDark from "../assets/img/logo-dark.svg"
import { joinClasses } from "../utils/utils"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { HiMenu } from "react-icons/hi"
import { FaAngleUp } from "react-icons/fa6"

const NavBar = ({ currentSection, darkSide, toggleDarkMode }) => {
  const [mobileMenuHidden, setMobileMenuHidden] = useState(true)
  const { t } = useTranslation()

  const handleMenuClick = () => {
    setMobileMenuHidden((prev) => !prev)
  }

  return (
    <>
      <header className="fixed w-screen backdrop-blur-sm drop-shadow-md z-50 flex justify-center font-raleway font-medium">
        <nav>
          <div className="w-screen max-w-7xl px-4 py-2 md:px-8 lg:px-32 flex justify-between items-center bg-neutral-200/40 dark:bg-neutral-900/40 xl:rounded-b-3xl">
            <a href="/" /* className="text-sm md:text-xl font-bold mt-3" style={{ fontFamily: "Signature" }} */>
              {/* Alejandro Botero */}
              <img src={logo} alt="Alejandro Botero Logo" className="h-8 dark:hidden" />
              <img src={logoDark} alt="Alejandro Botero Logo" className="h-8 hidden dark:block" />
            </a>
            <div className="hidden md:flex space-x-1 lg:space-x-3 items-center">
              {sections.map(
                ({ name }) =>
                  name !== "start" && (
                    <a
                      href={`#${name}`}
                      key={name}
                      className={joinClasses(
                        "p-1 md:inline-block mx-1  hover:text-orange-500 dark:hover:text-orange-400",
                        currentSection === name
                          ? "text-neutral-950 dark:text-neutral-50 border-b-2 border-b-neutral-800 hover:border-b-orange-500 dark:border-b-neutral-200 dark:hover:border-b-orange-400"
                          : "text-neutral-600 dark:text-neutral-300"
                      )}
                    >
                      {t(`navbar.${name}`)}
                    </a>
                  )
              )}
              <div className="w-1" />
              <LangSwitcher />
              <div className="w-0.5 lg:hidden" />
              <ThemeSwitcher {...{ darkSide, toggleDarkMode }} />
            </div>
            <HiMenu
              className={joinClasses(
                "w-8 h-8 md:hidden rounded",
                mobileMenuHidden || "bg-neutral-300 dark:bg-neutral-600"
              )}
              onClick={handleMenuClick}
            />
          </div>

          {/* Mobile Menu */}
          <div
            className={joinClasses(
              "md:-top-full md:hidden absolute bottom-full w-full drop-shadow-md -z-10",
              mobileMenuHidden ? "opacity-0 transition -translate-y-full" : "opacity-100 transition translate-y-full"
            )}
          >
            <div className="flex flex-col bg-neutral-50/90 dark:bg-neutral-600/90 py-3 ">
              <div className="h-14" />
              <div className="flex justify-center items-center space-x-10 my-3">
                <LangSwitcher />
                <ThemeSwitcher {...{ darkSide, toggleDarkMode }} />
              </div>
              {sections.map(
                ({ name }) =>
                  name !== "start" && (
                    <a
                      href={`#${name}`}
                      key={name}
                      onClick={handleMenuClick}
                      className={joinClasses(
                        "p-3 md:inline-block mx-1 text-neutral-500 dark:text-neutral-200 hover:text-orange-500 dark:hover:text-orange-400 text-lg text-center",
                        currentSection === name && " text-neutral-950 dark:text-neutral-50"
                      )}
                    >
                      {t(`navbar.${name}`)}
                    </a>
                  )
              )}
            </div>
          </div>
        </nav>
      </header>
      {/* Go back to top button */}
      {currentSection !== "start" && (
        <a
          href="#start"
          title={t("navbar.backToTop")}
          className="fixed right-8 bottom-28 text-4xl p-3 rounded-full bg-slate-400 dark:bg-slate-600 hover:bg-orange-350 dark:hover:bg-orange-350 active:text-orange-50 dark:active:text-orange-950 z-50"
        >
          <FaAngleUp />
        </a>
      )}
    </>
  )
}

export default NavBar
