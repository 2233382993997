import { joinClasses } from "../utils/utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BiCheck, BiCheckDouble } from "react-icons/bi"
import { BsDot } from "react-icons/bs"

const Chat = ({ messages, speed = 10000 /* millisec/message */ }) => {
  const [hours, setHours] = useState([])
  const [msgIndex, setMsgIndex] = useState(0)
  const [delivered, setDelivered] = useState(false)
  const [read, setRead] = useState(false)
  const [writing, setWriting] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    const interval = setInterval(() => {
      // Stop if all messages are loaded
      if (msgIndex >= messages.length) return
      // Reset status for the new message
      setDelivered(false)
      setRead(false)
      setWriting(false)
      // Get time
      const d = new Date()
      const h = d.getHours()
      const m = String(d.getMinutes()).padStart(2, "0")
      setHours([...hours, `${h}:${m} ${h < 12 ? "AM" : "PM"}`])
      // Set timers
      setTimeout(() => setDelivered(true), speed * 0.15)
      setTimeout(() => setRead(true), speed * 0.25)
      setMsgIndex((prev) => {
        setTimeout(() => prev < messages.length - 1 && setWriting(true), speed * 0.4)
        return prev + 1
      })
    }, speed)
    //Clear the interval
    return () => clearInterval(interval)
  }, [hours, messages, msgIndex, speed, setDelivered, setMsgIndex, setRead, setWriting])

  return (
    <div className="bg-neutral-200 dark:bg-neutral-700 border-neutral-400 dark:border-neutral-500 border-[1px] w-full h-full min-h-[22rem] max-h-[22rem] md:max-h-[30rem] lg:max-h-[35rem] xl:max-h-[40rem] pt-px rounded-lg flex flex-col justify-end items-end text-neutral-600 dark:text-neutral-300 text-sm lg:text-lg relative overflow-hidden">
      <div className="max-h-full overflow-x-hidden overflow-y-auto flex flex-row flex-wrap justify-end items-end gap-y-1.5 px-6 py-2">
        {messages.slice(0, msgIndex).map((el, i) => (
          <span
            key={i}
            className={joinClasses(
              "block rounded-[1rem] bg-emerald-400 dark:bg-emerald-700 bg-opacity-40 dark:bg-opacity-50 px-4 py-2",
              i === msgIndex - 1 && "rounded-br-none"
            )}
          >
            {el}
            <span className="float-right text-base md:text-xl ml-2 mt-1 -mr-1 -mb-1 flex flex-nowrap space-x-0.5 items-end text-neutral-500 dark:text-stone-400">
              <span className="text-[0.6rem] leading-4 lg:text-xs font-light">{hours[i]}</span>
              {i < msgIndex - 1 || delivered ? (
                <BiCheckDouble className={(i < msgIndex - 1 || read) && "text-sky-500"} />
              ) : (
                <BiCheck />
              )}
            </span>
          </span>
        ))}
        {writing && (
          <div className="flex items-end text-lg md:text-xl lg:text-2xl min-h-10">
            <BsDot className="-mx-1.5 animate-bounce animation-delay-0" />
            <BsDot className="-mx-1.5 animate-bounce animation-delay-75" />
            <BsDot className="-mx-1.5 animate-bounce animation-delay-150" />
          </div>
        )}
      </div>
      <input
        type="text"
        className="w-11/12 mx-auto mt-1 mb-2 h-8 px-3 py-5 rounded-xl bg-neutral-100 dark:bg-neutral-800 border-neutral-300 dark:border-neutral-600 border-[1px]"
        placeholder={t("chat.write")}
        disabled
      />
    </div>
  )
}

export default Chat
