import Contact from "./Contact"
import Cover from "./Cover"
import NavBar from "./NavBar"
import Reviews from "./Reviews"
import Work from "./Work"
import useDarkSide from "../hooks/useDarkSide"
import fotoAlejandro from "../assets/img/foto_alejandro.jpg"
import { resources } from "../utils/translationResources"
import i18n from "i18next"
import { initReactI18next, useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { FloatingWhatsApp } from "react-floating-whatsapp"
import { InView, useInView } from "react-intersection-observer"

export const sections = [
  { name: "start", component: <Cover /> },
  { name: "work", component: <Work /> },
  { name: "reviews", component: <Reviews /> },
  { name: "contact", component: <Contact /> },
]

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language"),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

const App = () => {
  const [currentSection, setCurrentSection] = useState(sections[0].name)
  const { ref } = useInView()
  const setInView = (_, entry) => {
    if (entry.isIntersecting) {
      setCurrentSection(entry.target.getAttribute("id"))
    }
  }

  const [colorTheme, setTheme] = useDarkSide()
  const [darkSide, setDarkSide] = useState(colorTheme === "light")
  const toggleDarkMode = () => {
    setTheme(colorTheme)
    setDarkSide((prev) => !prev)
  }

  useEffect(() => {
    document.body.className = darkSide
      ? "dark:bg-neutral-800 dark:text-neutral-200 "
      : "bg-neutral-100 text-neutral-800"
    return () => {
      document.body.className = ""
    }
  })

  const { t } = useTranslation()

  return (
    <div>
      <NavBar {...{ currentSection, darkSide, toggleDarkMode }} />
      <main ref={ref}>
        {sections.map(({ name, component }) => (
          <InView
            onChange={setInView}
            rootMargin={`0% 0% -${name === "contact" ? 20 : 98}% 0%`}
            threshold={name === "contact" ? 0.5 : 0}
            key={name}
          >
            {({ ref }) => (
              <section ref={ref} id={name}>
                {component}
              </section>
            )}
          </InView>
        ))}
        <FloatingWhatsApp
          phoneNumber="573113066308"
          accountName="Alejandro Botero"
          avatar={fotoAlejandro}
          chatMessage={t("whatsapp.chatMessage")}
          placeholder={t("whatsapp.placeholder")}
          statusMessage={t("whatsapp.statusMessage")}
          darkMode={darkSide}
        />
      </main>
    </div>
  )
}

export default App
