export const skills = [
  "HTML-5",
  "CSS",
  "Sass",
  "Bootstrap",
  "Tailwind CSS",
  "JavaScript",
  "TypeScript",
  "ReactJS",
  "React Native",
  "NodeJS",
  "Next.js",
  "Python",
  "Flask",
  "Object Oriented Programming",
  "MySQL",
  "Relational Databases",
  "MongoDB",
  "Non-relational Databases",
  "RESTful API",
  "Search Engine Optimization",
  "Rive.app",
  "Git Version Control",
  "Bash / Shell",
  "Docker",
  "Linux",
  "Windows",
  "MacOS",
  "Blockchain",
  "Solidity",
]
