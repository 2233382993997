import { skills } from "../data/skills"

/*
 InfiniteLoopSlider
 https://codepen.io/ykadosh/pen/KKezJzz
 */

const InfiniteLoopSlider = ({ children }) => {
  return (
    <div className="relative overflow-hidden w-screen lg:w-auto">
      <div className="flex w-fit items-center space-x-2 animate-infinite-loop">
        {children}
        {children}
        {children}
      </div>
    </div>
  )
}

const Skills = () => {
  return (
    <div className="lg:absolute lg:-bottom-[7.5rem] xl:-bottom-28 lg:translate-y-8 left-0 right-0 lg:-left-[5.25rem] lg:-right-[5.75rem] xl:-left-[5rem] xl:-right-[7.5rem] mt-6">
      <InfiniteLoopSlider>
        {skills.map((el, i) => (
          <span
            className="w-max px-5 py-3 mt-3 md:mt-8 mb-8 rounded-lg bg-slate-700 text-slate-200 dark:bg-slate-400 dark:text-slate-800 text-sm md:text-lg"
            key={i}
          >
            {el}
          </span>
        ))}
      </InfiniteLoopSlider>
    </div>
  )
}

export default Skills
