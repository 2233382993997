import { useEffect, useState } from "react"

const useDarkSide = () => {
  const [theme, setTheme] = useState(localStorage.theme)
  const colorTheme = theme === "dark" ? "light" : "dark"

  useEffect(() => {
    const root = document.documentElement
    root.classList.remove(colorTheme)
    root.classList.add(theme)

    // save theme to local storage
    localStorage.setItem("theme", theme)
  }, [theme, colorTheme])

  return [colorTheme, setTheme]
}

export default useDarkSide
