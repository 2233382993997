import acuaturImg from "../assets/img/acuatur.png"
import iperioImg from "../assets/img/iperio.png"
import sigmaImg from "../assets/img/sigma.png"
import tableroImg from "../assets/img/tablero.png"
import barrocoImg from "../assets/img/barroco.png"
import acuaturVideo from "../assets/video/acuatur-demo.mp4"
import iperioVideo from "../assets/video/iperio-demo.mp4"
import sigmaVideo from "../assets/video/sigma-demo.mp4"
import tableroVideo from "../assets/video/tablero-demo.mp4"
import barrocoVideo from "../assets/video/barroco.mp4"

export const projects = [
  {
    name: "Barroco",
    tag: "barroco",
    img: barrocoImg,
    video: barrocoVideo,
    url: "https://pruebas.restaurantebarroco.com",
    type: "production",
  },
  {
    name: "i-Perio",
    tag: "iperio",
    img: iperioImg,
    video: iperioVideo,
    url: "https://iperio-demo.albotero.com",
    github: "i-perio",
    type: "demo",
  },
  {
    name: "Sigma",
    tag: "sigma",
    img: sigmaImg,
    video: sigmaVideo,
    url: "https://sigma-demo.albotero.com",
    github: "sigma",
    type: "demo",
  },
  {
    name: "Acuatur",
    tag: "acuatur",
    img: acuaturImg,
    video: acuaturVideo,
    url: "https://acuatur-demo.albotero.com",
    github: "acuatur",
    type: "demo",
  },
  {
    name: "Tablero",
    tag: "tablero",
    img: tableroImg,
    video: tableroVideo,
    url: "https://tablero-demo.albotero.com",
    github: "tablero",
    type: "demo",
  },
]
