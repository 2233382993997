import codeSnippetsEn from "../locales/en/codeSnippets.json"
import codeSnippetsEs from "../locales/es/codeSnippets.json"
import projectsEn from "../locales/en/projects.json"
import projectsEs from "../locales/es/projects.json"
import reviewsEn from "../locales/en/reviews.json"
import reviewsEs from "../locales/es/reviews.json"
import translationEn from "../locales/en/translation.json"
import translationEs from "../locales/es/translation.json"

export const resources = {
  en: {
    translation: translationEn,
    projects: projectsEn,
    reviews: reviewsEn,
    codeSnippets: codeSnippetsEn,
  },
  es: {
    translation: translationEs,
    projects: projectsEs,
    reviews: reviewsEs,
    codeSnippets: codeSnippetsEs,
  },
}
