import ProjectModal from "./ProjectModal"
import { Modal } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaCode, FaDesktop, FaPlus } from "react-icons/fa6"

const Project = ({ projectData, buttons }) => {
  const { github, img, name, shortDesc, url, type } = projectData
  const [modalShown, setModalShown] = useState(false)
  const { t } = useTranslation()

  const buttonClass = "my-2 py-2 px-2 border-white border-2 rounded-full hover:text-orange-500 hover:border-orange-500"

  const handleModalShow = () => {
    setModalShown((prev) => !prev)
  }

  return (
    <>
      <div className="relative w-10/12 md:w-4/5 aspect-video overflow-hidden rounded-xl mx-auto my-8 md:my-12 shadow-md shadow-neutral-600 dark:shadow-neutral-600">
        <div
          className="absolute w-full h-full bg-cover bg-no-repeat dark:opacity-80 dark:grayscale-[35%]"
          style={{ backgroundImage: `url('${img}')` }}
        ></div>
        <div
          className="absolute w-full h-full overflow-hidden rounded-xl px-6 md:px-12 py-6 flex flex-col items-center justify-center select-none text-neutral-300 hover:text-white text-center font-baar-metanoia md:opacity-0 hover:opacity-100"
          style={{ backgroundColor: "rgb(0,0,0,0.6)" }}
        >
          <h3 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold">{name}</h3>
          <h4 className="text-md md:text-lg xl:text-xl italic mt-3">{shortDesc}</h4>
          <div className="flex space-x-3 md:space-x-5 mt-5 text-lg lg:text-2xl text-center">
            <button className={buttonClass} title={buttons.moreInfo} onClick={handleModalShow}>
              <FaPlus />
            </button>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              title={t(type === "demo" ? "work.demo" : "work.live")}
              className={buttonClass}
            >
              <FaDesktop />
            </a>
            { type === "demo" && (
              <a
                href={`https://github.com/albotero/${github}`}
                target="_blank"
                rel="noreferrer"
                title={buttons.code}
                className={buttonClass}
              >
                <FaCode />
              </a>
            )}
          </div>
        </div>
      </div>
      {/* Project Info */}
      <Modal open={modalShown} onClose={handleModalShow}>
        <>
          <ProjectModal projectData={projectData} handleModalShow={handleModalShow} />
        </>
      </Modal>
    </>
  )
}

export default Project
