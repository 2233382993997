import { CircleFlag } from "react-circle-flags"
import { reviews } from "../data/reviews"
import { loadData } from "../utils/utils"
import LoadMore from "./LoadMore"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaRegStar, FaRegStarHalfStroke, FaStar } from "react-icons/fa6"

const ReviewCard = ({ review }) => {
  const { id, client, country, stars } = review
  const { t } = useTranslation(["translation", "reviews"])

  const comment = t(`reviews:${id}.comment`)
  const commentLength = 240
  const shortComment = comment.length > commentLength && comment.slice(0, comment.indexOf(" ", commentLength))

  const secondaryColor = ["#64748b", "#8b6479", "#658b64", "#8b7b64"][id % 4]

  return (
    <div className="relative drop-shadow-2xl w-full lg:w-1/3 xl:w-1/4">
      <div
        className="absolute w-[140%] h-[60%] rounded-3xl left-1/2 top-[40%] md:top-[45%]"
        style={{
          transform: "perspective(90rem) rotateX(8deg) rotateY(-25deg) translate(-63%, -40%)",
          backgroundColor: secondaryColor,
        }}
      />
      <div
        className="bg-slate-100 dark:bg-neutral-800 rounded-3xl my-10 md:mt-20 md:mb-6 px-4 py-5"
        style={{
          transform: "perspective(70rem) rotateY(-5deg)",
        }}
      >
        <h3 className="text-xl text-center font-semibold font-baar-metanoia border-b-2 border-b-neutral-600 dark:border-b-neutral-400 w-3/4 mx-auto">
          {client}
        </h3>
        <p className="text-md text-center italic text-neutral-600 dark:text-neutral-400 leading-4 w-4/5 my-2 mx-auto">
          {t(`reviews:${id}.job`)}
        </p>
        <div className="flex space-x-1 items-center justify-center text-amber-500 dark:text-yellow-300 md:text-lg xl:text-xl my-3">
          {Array(5)
            .fill()
            .map((_, i) => {
              if (stars <= i) return <FaRegStar key={i} />
              if (stars < i + 1) return <FaRegStarHalfStroke key={i} />
              return <FaStar key={i} />
            })}
        </div>
        <div
          className="absolute -top-6 -right-6 h-20 w-20 rounded-full overflow-hidden border-[.5rem]"
          style={{ borderColor: secondaryColor }}
        >
          <CircleFlag
            countryCode={country}
            className="w-full h-full object-cover dark:grayscale-[25%] dark:opacity-95"
          />
        </div>
        <div className="flex px-1 justify-center gap-0">
          <span className="self-start -rotate-12 text-neutral-600 dark:text-neutral-400 font-pt-sans text-5xl mt-2.5 -mr-0.5">
            {"\u201C"}
          </span>
          <span className="text-center self-center leading-5 font-raleway my-3">
            {shortComment || comment} {shortComment && <span className="dots" />}
          </span>
          <span className="self-end rotate-12 text-neutral-600 dark:text-neutral-400 font-pt-sans text-5xl -mb-2.5 -ml-0.5">
            {"\u201D"}
          </span>
        </div>
      </div>
    </div>
  )
}

const Reviews = () => {
  const { t } = useTranslation()
  const [currIndex, setCurrIndex] = useState(0)
  const { data, allDataLoaded, loadMoreData } = loadData(reviews, currIndex + 3, setCurrIndex)

  return (
    <div className="flex flex-col justify-center bg-slate-300 dark:bg-zinc-900 pt-32 pb-8">
      <h2 className="text-3xl md:text-4xl xl:text-5xl text-center font-raleway">{t("reviews.title")}</h2>
      <div className="flex flex-wrap gap-x-20 items-start justify-center w-full max-w-[82.5%] md:max-w-[50%] lg:max-w-[85%] xl:max-w-[90%] mx-auto">
        {data.map((rev) => (
          <ReviewCard key={rev.id} review={rev} />
        ))}
      </div>
      <LoadMore action={loadMoreData} hidden={allDataLoaded} />
    </div>
  )
}

export default Reviews
