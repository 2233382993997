import Chat from "./Chat"
import React from "react"
import { useTranslation } from "react-i18next"
import { IoCloseSharp } from "react-icons/io5"
import { LuMonitorPlay } from "react-icons/lu"

const ProjectModal = ({ projectData: { desc, img, name, tag, type, url, video }, handleModalShow }) => {
  const { t } = useTranslation()
  const messages = t(`projects:${tag}.messages`).split(/\|/g)

  return (
    <div className="absolute w-11/12 max-h-[80%] p-0.5 bg-neutral-50 dark:bg-neutral-800 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl shadow-md shadow-neutral-800 dark:shadow-neutral-600 flex flex-col overflow-hidden">
      <IoCloseSharp
        className="fixed self-start h-6 w-6 lg:h-8 lg:w-8 mx-3 my-2 cursor-pointer hover:text-orange-500 dark:hover:text-orange-400 bg-opacity-20 dark:bg-opacity-20 hover:bg-opacity-50 dark:hover:bg-opacity-50 bg-neutral-300 dark:bg-neutral-600 rounded-lg"
        onClick={handleModalShow}
      />
      <div className="flex flex-row flex-grow-1 flex-wrap pt-8 md:px-2 lg:px-4  min-h-0 overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-10 px-6 overflow-auto max-h-[85%]">
          <h1 className="col-span-2 text-xl lg:text-2xl font-semibold md:px-4 md:pt-5 pb-2">{name}</h1>
          <p className="col-span-2 text-sm lg:text-lg md:leading-6 md:px-4 pb-4 md:pb-2 text-neutral-600 dark:text-neutral-300">
            {desc}
          </p>
          <p className="col-span-2 text-xs md:text-sm italic md:px-4 pb-4 md:pb-0 text-neutral-600 dark:text-neutral-300">
            * {t(type === "demo" ? "permissionDemo" : "permissionLive")}
          </p>
          <video
            className="col-span-2 md:row-start-1 w-full rounded-lg shadow shadow-zinc-700 dark:shadow-zinc-950"
            poster={img}
            controls
            autoPlay
            loop
          >
            <source src={video} type="video/mp4" />
            {t("videoNotSupported")}
          </video>
          {/* Chat */}
          <div className="row-span-4 row-start-5 md:row-start-1 md:col-start-3 pt-6 md:pt-2 w-full h-full">
            <Chat messages={messages} />
          </div>
          <a
            className="col-span-3 flex items-center gap-x-2 w-max mx-auto my-6 md:my-8 text-sm lg:text-lg hover:text-orange-500 hover:font-semibold cursor-pointer"
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            <LuMonitorPlay className="h-6 w-6 lg:h-8 lg:w-8" />
            {" " + t(type === "demo" ? "work.goToDemo" : "work.goToLive")}
          </a>
        </div>
      </div>
    </div>
  )
}

export default ProjectModal
