export const countries = [
  {
    title: "Afghanistan",
    value: "AF",
    phoneCode: "93",
  },
  {
    title: "Albania",
    value: "AL",
    phoneCode: "355",
  },
  {
    title: "Algeria",
    value: "DZ",
    phoneCode: "213",
  },
  {
    title: "American Samoa",
    value: "AS",
    phoneCode: "1-684",
  },
  {
    title: "Andorra",
    value: "AD",
    phoneCode: "376",
  },
  {
    title: "Angola",
    value: "AO",
    phoneCode: "244",
  },
  {
    title: "Anguilla",
    value: "AI",
    phoneCode: "1-264",
  },
  {
    title: "Argentina",
    value: "AR",
    phoneCode: "54",
  },
  {
    title: "Armenia",
    value: "AM",
    phoneCode: "374",
  },
  {
    title: "Aruba",
    value: "AW",
    phoneCode: "297",
  },
  {
    title: "Australia",
    value: "AU",
    phoneCode: "61",
  },
  {
    title: "Austria",
    value: "AT",
    phoneCode: "43",
  },
  {
    title: "Azerbaijan",
    value: "AZ",
    phoneCode: "994",
  },
  {
    title: "Bahamas",
    value: "BS",
    phoneCode: "1-242",
  },
  {
    title: "Bahrain",
    value: "BH",
    phoneCode: "973",
  },
  {
    title: "Bangladesh",
    value: "BD",
    phoneCode: "880",
  },
  {
    title: "Barbados",
    value: "BB",
    phoneCode: "1-246",
  },
  {
    title: "Belarus",
    value: "BY",
    phoneCode: "375",
  },
  {
    title: "Belgium",
    value: "BE",
    phoneCode: "32",
  },
  {
    title: "Belize",
    value: "BZ",
    phoneCode: "501",
  },
  {
    title: "Benin",
    value: "BJ",
    phoneCode: "229",
  },
  {
    title: "Bermuda",
    value: "BM",
    phoneCode: "1-441",
  },
  {
    title: "Bhutan",
    value: "BT",
    phoneCode: "975",
  },
  {
    title: "Bolivia",
    value: "BO",
    phoneCode: "591",
  },
  {
    title: "Bosnia and Herzegovina",
    value: "BA",
    phoneCode: "387",
  },
  {
    title: "Botswana",
    value: "BW",
    phoneCode: "267",
  },
  {
    title: "Brazil",
    value: "BR",
    phoneCode: "55",
  },
  {
    title: "British Virgin Islands",
    value: "VG",
    phoneCode: "1-284",
  },
  {
    title: "Brunei",
    value: "BN",
    phoneCode: "673",
  },
  {
    title: "Bulgaria",
    value: "BG",
    phoneCode: "359",
  },
  {
    title: "Burkina Faso",
    value: "BF",
    phoneCode: "226",
  },
  {
    title: "Burundi",
    value: "BI",
    phoneCode: "257",
  },
  {
    title: "Cambodia",
    value: "KH",
    phoneCode: "855",
  },
  {
    title: "Cameroon",
    value: "CM",
    phoneCode: "237",
  },
  {
    title: "Canada",
    value: "CA",
    phoneCode: "1",
  },
  {
    title: "Cape Verde",
    value: "CV",
    phoneCode: "238",
  },
  {
    title: "Cayman Islands",
    value: "KY",
    phoneCode: "1-345",
  },
  {
    title: "Central African Republic",
    value: "CF",
    phoneCode: "236",
  },
  {
    title: "Chad",
    value: "TD",
    phoneCode: "235",
  },
  {
    title: "Chile",
    value: "CL",
    phoneCode: "56",
  },
  {
    title: "China",
    value: "CN",
    phoneCode: "86",
  },
  {
    title: "Colombia",
    value: "CO",
    phoneCode: "57",
  },
  {
    title: "Comoros",
    value: "KM",
    phoneCode: "269",
  },
  {
    title: "Cook Islands",
    value: "CK",
    phoneCode: "682",
  },
  {
    title: "Costa Rica",
    value: "CR",
    phoneCode: "506",
  },
  {
    title: "Croatia",
    value: "HR",
    phoneCode: "385",
  },
  {
    title: "Cuba",
    value: "CU",
    phoneCode: "53",
  },
  {
    title: "Curacao",
    value: "CW",
    phoneCode: "599",
  },
  {
    title: "Cyprus",
    value: "CY",
    phoneCode: "357",
  },
  {
    title: "Czech Republic",
    value: "CZ",
    phoneCode: "420",
  },
  {
    title: "Democratic Republic of the Congo",
    value: "CD",
    phoneCode: "243",
  },
  {
    title: "Denmark",
    value: "DK",
    phoneCode: "45",
  },
  {
    title: "Djibouti",
    value: "DJ",
    phoneCode: "253",
  },
  {
    title: "Dominica",
    value: "DM",
    phoneCode: "1-767",
  },
  {
    title: "Dominican Republic",
    value: "DO",
    phoneCode: "1-809",
  },
  {
    title: "East Timor",
    value: "TL",
    phoneCode: "670",
  },
  {
    title: "Ecuador",
    value: "EC",
    phoneCode: "593",
  },
  {
    title: "Egypt",
    value: "EG",
    phoneCode: "20",
  },
  {
    title: "El Salvador",
    value: "SV",
    phoneCode: "503",
  },
  {
    title: "Eritrea",
    value: "ER",
    phoneCode: "291",
  },
  {
    title: "Estonia",
    value: "EE",
    phoneCode: "372",
  },
  {
    title: "Ethiopia",
    value: "ET",
    phoneCode: "251",
  },
  {
    title: "Faroe Islands",
    value: "FO",
    phoneCode: "298",
  },
  {
    title: "Fiji",
    value: "FJ",
    phoneCode: "679",
  },
  {
    title: "Finland",
    value: "FI",
    phoneCode: "358",
  },
  {
    title: "France",
    value: "FR",
    phoneCode: "33",
  },
  {
    title: "French Polynesia",
    value: "PF",
    phoneCode: "689",
  },
  {
    title: "Gabon",
    value: "GA",
    phoneCode: "241",
  },
  {
    title: "Gambia",
    value: "GM",
    phoneCode: "220",
  },
  {
    title: "Georgia",
    value: "GE",
    phoneCode: "995",
  },
  {
    title: "Germany",
    value: "DE",
    phoneCode: "49",
  },
  {
    title: "Ghana",
    value: "GH",
    phoneCode: "233",
  },
  {
    title: "Greece",
    value: "GR",
    phoneCode: "30",
  },
  {
    title: "Greenland",
    value: "GL",
    phoneCode: "299",
  },
  {
    title: "Grenada",
    value: "GD",
    phoneCode: "1-473",
  },
  {
    title: "Guam",
    value: "GU",
    phoneCode: "1-671",
  },
  {
    title: "Guatemala",
    value: "GT",
    phoneCode: "502",
  },
  {
    title: "Guernsey",
    value: "GG",
    phoneCode: "44-1481",
  },
  {
    title: "Guinea",
    value: "GN",
    phoneCode: "224",
  },
  {
    title: "Guinea-Bissau",
    value: "GW",
    phoneCode: "245",
  },
  {
    title: "Guyana",
    value: "GY",
    phoneCode: "592",
  },
  {
    title: "Haiti",
    value: "HT",
    phoneCode: "509",
  },
  {
    title: "Honduras",
    value: "HN",
    phoneCode: "504",
  },
  {
    title: "Hong Kong",
    value: "HK",
    phoneCode: "852",
  },
  {
    title: "Hungary",
    value: "HU",
    phoneCode: "36",
  },
  {
    title: "Iceland",
    value: "IS",
    phoneCode: "354",
  },
  {
    title: "India",
    value: "IN",
    phoneCode: "91",
  },
  {
    title: "Indonesia",
    value: "ID",
    phoneCode: "62",
  },
  {
    title: "Iran",
    value: "IR",
    phoneCode: "98",
  },
  {
    title: "Iraq",
    value: "IQ",
    phoneCode: "964",
  },
  {
    title: "Ireland",
    value: "IE",
    phoneCode: "353",
  },
  {
    title: "Isle of Man",
    value: "IM",
    phoneCode: "44-1624",
  },
  {
    title: "Israel",
    value: "IL",
    phoneCode: "972",
  },
  {
    title: "Italy",
    value: "IT",
    phoneCode: "39",
  },
  {
    title: "Ivory Coast",
    value: "CI",
    phoneCode: "225",
  },
  {
    title: "Jamaica",
    value: "JM",
    phoneCode: "1-876",
  },
  {
    title: "Japan",
    value: "JP",
    phoneCode: "81",
  },
  {
    title: "Jersey",
    value: "JE",
    phoneCode: "44-1534",
  },
  {
    title: "Jordan",
    value: "JO",
    phoneCode: "962",
  },
  {
    title: "Kazakhstan",
    value: "KZ",
    phoneCode: "7",
  },
  {
    title: "Kenya",
    value: "KE",
    phoneCode: "254",
  },
  {
    title: "Kiribati",
    value: "KI",
    phoneCode: "686",
  },
  {
    title: "Kosovo",
    value: "XK",
    phoneCode: "383",
  },
  {
    title: "Kuwait",
    value: "KW",
    phoneCode: "965",
  },
  {
    title: "Kyrgyzstan",
    value: "KG",
    phoneCode: "996",
  },
  {
    title: "Laos",
    value: "LA",
    phoneCode: "856",
  },
  {
    title: "Latvia",
    value: "LV",
    phoneCode: "371",
  },
  {
    title: "Lebanon",
    value: "LB",
    phoneCode: "961",
  },
  {
    title: "Lesotho",
    value: "LS",
    phoneCode: "266",
  },
  {
    title: "Liberia",
    value: "LR",
    phoneCode: "231",
  },
  {
    title: "Libya",
    value: "LY",
    phoneCode: "218",
  },
  {
    title: "Liechtenstein",
    value: "LI",
    phoneCode: "423",
  },
  {
    title: "Lithuania",
    value: "LT",
    phoneCode: "370",
  },
  {
    title: "Luxembourg",
    value: "LU",
    phoneCode: "352",
  },
  {
    title: "Macau",
    value: "MO",
    phoneCode: "853",
  },
  {
    title: "Macedonia",
    value: "MK",
    phoneCode: "389",
  },
  {
    title: "Madagascar",
    value: "MG",
    phoneCode: "261",
  },
  {
    title: "Malawi",
    value: "MW",
    phoneCode: "265",
  },
  {
    title: "Malaysia",
    value: "MY",
    phoneCode: "60",
  },
  {
    title: "Maldives",
    value: "MV",
    phoneCode: "960",
  },
  {
    title: "Mali",
    value: "ML",
    phoneCode: "223",
  },
  {
    title: "Malta",
    value: "MT",
    phoneCode: "356",
  },
  {
    title: "Marshall Islands",
    value: "MH",
    phoneCode: "692",
  },
  {
    title: "Mauritania",
    value: "MR",
    phoneCode: "222",
  },
  {
    title: "Mauritius",
    value: "MU",
    phoneCode: "230",
  },
  {
    title: "Mayotte",
    value: "YT",
    phoneCode: "262",
  },
  {
    title: "Mexico",
    value: "MX",
    phoneCode: "52",
  },
  {
    title: "Micronesia",
    value: "FM",
    phoneCode: "691",
  },
  {
    title: "Moldova",
    value: "MD",
    phoneCode: "373",
  },
  {
    title: "Monaco",
    value: "MC",
    phoneCode: "377",
  },
  {
    title: "Mongolia",
    value: "MN",
    phoneCode: "976",
  },
  {
    title: "Montenegro",
    value: "ME",
    phoneCode: "382",
  },
  {
    title: "Morocco",
    value: "MA",
    phoneCode: "212",
  },
  {
    title: "Mozambique",
    value: "MZ",
    phoneCode: "258",
  },
  {
    title: "Myanmar",
    value: "MM",
    phoneCode: "95",
  },
  {
    title: "Namibia",
    value: "NA",
    phoneCode: "264",
  },
  {
    title: "Nepal",
    value: "NP",
    phoneCode: "977",
  },
  {
    title: "Netherlands",
    value: "NL",
    phoneCode: "31",
  },
  {
    title: "Netherlands Antilles",
    value: "AN",
    phoneCode: "599",
  },
  {
    title: "New Caledonia",
    value: "NC",
    phoneCode: "687",
  },
  {
    title: "New Zealand",
    value: "NZ",
    phoneCode: "64",
  },
  {
    title: "Nicaragua",
    value: "NI",
    phoneCode: "505",
  },
  {
    title: "Niger",
    value: "NE",
    phoneCode: "227",
  },
  {
    title: "Nigeria",
    value: "NG",
    phoneCode: "234",
  },
  {
    title: "North Korea",
    value: "KP",
    phoneCode: "850",
  },
  {
    title: "Northern Mariana Islands",
    value: "MP",
    phoneCode: "1-670",
  },
  {
    title: "Norway",
    value: "NO",
    phoneCode: "47",
  },
  {
    title: "Oman",
    value: "OM",
    phoneCode: "968",
  },
  {
    title: "Pakistan",
    value: "PK",
    phoneCode: "92",
  },
  {
    title: "Palestine",
    value: "PS",
    phoneCode: "970",
  },
  {
    title: "Panama",
    value: "PA",
    phoneCode: "507",
  },
  {
    title: "Papua New Guinea",
    value: "PG",
    phoneCode: "675",
  },
  {
    title: "Paraguay",
    value: "PY",
    phoneCode: "595",
  },
  {
    title: "Peru",
    value: "PE",
    phoneCode: "51",
  },
  {
    title: "Philippines",
    value: "PH",
    phoneCode: "63",
  },
  {
    title: "Poland",
    value: "PL",
    phoneCode: "48",
  },
  {
    title: "Portugal",
    value: "PT",
    phoneCode: "351",
  },
  {
    title: "Puerto Rico",
    value: "PR",
    phoneCode: "1-787",
  },
  {
    title: "Qatar",
    value: "QA",
    phoneCode: "974",
  },
  {
    title: "Republic of the Congo",
    value: "CG",
    phoneCode: "242",
  },
  {
    title: "Reunion",
    value: "RE",
    phoneCode: "262",
  },
  {
    title: "Romania",
    value: "RO",
    phoneCode: "40",
  },
  {
    title: "Russia",
    value: "RU",
    phoneCode: "7",
  },
  {
    title: "Rwanda",
    value: "RW",
    phoneCode: "250",
  },
  {
    title: "Saint Kitts and Nevis",
    value: "KN",
    phoneCode: "1-869",
  },
  {
    title: "Saint Lucia",
    value: "LC",
    phoneCode: "1-758",
  },
  {
    title: "Saint Martin",
    value: "MF",
    phoneCode: "590",
  },
  {
    title: "Saint Pierre and Miquelon",
    value: "PM",
    phoneCode: "508",
  },
  {
    title: "Saint Vincent and the Grenadines",
    value: "VC",
    phoneCode: "1-784",
  },
  {
    title: "Samoa",
    value: "WS",
    phoneCode: "685",
  },
  {
    title: "San Marino",
    value: "SM",
    phoneCode: "378",
  },
  {
    title: "Sao Tome and Principe",
    value: "ST",
    phoneCode: "239",
  },
  {
    title: "Saudi Arabia",
    value: "SA",
    phoneCode: "966",
  },
  {
    title: "Senegal",
    value: "SN",
    phoneCode: "221",
  },
  {
    title: "Serbia",
    value: "RS",
    phoneCode: "381",
  },
  {
    title: "Seychelles",
    value: "SC",
    phoneCode: "248",
  },
  {
    title: "Sierra Leone",
    value: "SL",
    phoneCode: "232",
  },
  {
    title: "Singapore",
    value: "SG",
    phoneCode: "65",
  },
  {
    title: "Sint Maarten",
    value: "SX",
    phoneCode: "1-721",
  },
  {
    title: "Slovakia",
    value: "SK",
    phoneCode: "421",
  },
  {
    title: "Slovenia",
    value: "SI",
    phoneCode: "386",
  },
  {
    title: "Solomon Islands",
    value: "SB",
    phoneCode: "677",
  },
  {
    title: "Somalia",
    value: "SO",
    phoneCode: "252",
  },
  {
    title: "South Africa",
    value: "ZA",
    phoneCode: "27",
  },
  {
    title: "South Korea",
    value: "KR",
    phoneCode: "82",
  },
  {
    title: "South Sudan",
    value: "SS",
    phoneCode: "211",
  },
  {
    title: "Spain",
    value: "ES",
    phoneCode: "34",
  },
  {
    title: "Sri Lanka",
    value: "LK",
    phoneCode: "94",
  },
  {
    title: "Sudan",
    value: "SD",
    phoneCode: "249",
  },
  {
    title: "Suriname",
    value: "SR",
    phoneCode: "597",
  },
  {
    title: "Swaziland",
    value: "SZ",
    phoneCode: "268",
  },
  {
    title: "Sweden",
    value: "SE",
    phoneCode: "46",
  },
  {
    title: "Switzerland",
    value: "CH",
    phoneCode: "41",
  },
  {
    title: "Syria",
    value: "SY",
    phoneCode: "963",
  },
  {
    title: "Taiwan",
    value: "TW",
    phoneCode: "886",
  },
  {
    title: "Tajikistan",
    value: "TJ",
    phoneCode: "992",
  },
  {
    title: "Tanzania",
    value: "TZ",
    phoneCode: "255",
  },
  {
    title: "Thailand",
    value: "TH",
    phoneCode: "66",
  },
  {
    title: "Togo",
    value: "TG",
    phoneCode: "228",
  },
  {
    title: "Tonga",
    value: "TO",
    phoneCode: "676",
  },
  {
    title: "Trinidad and Tobago",
    value: "TT",
    phoneCode: "1-868",
  },
  {
    title: "Tunisia",
    value: "TN",
    phoneCode: "216",
  },
  {
    title: "Turkey",
    value: "TR",
    phoneCode: "90",
  },
  {
    title: "Turkmenistan",
    value: "TM",
    phoneCode: "993",
  },
  {
    title: "Turks and Caicos Islands",
    value: "TC",
    phoneCode: "1-649",
  },
  {
    title: "Tuvalu",
    value: "TV",
    phoneCode: "688",
  },
  {
    title: "U.S. Virgin Islands",
    value: "VI",
    phoneCode: "1-340",
  },
  {
    title: "Uganda",
    value: "UG",
    phoneCode: "256",
  },
  {
    title: "Ukraine",
    value: "UA",
    phoneCode: "380",
  },
  {
    title: "United Arab Emirates",
    value: "AE",
    phoneCode: "971",
  },
  {
    title: "United Kingdom",
    value: "GB",
    phoneCode: "44",
  },
  {
    title: "United States",
    value: "US",
    phoneCode: "1",
  },
  {
    title: "Uruguay",
    value: "UY",
    phoneCode: "598",
  },
  {
    title: "Uzbekistan",
    value: "UZ",
    phoneCode: "998",
  },
  {
    title: "Vanuatu",
    value: "VU",
    phoneCode: "678",
  },
  {
    title: "Venezuela",
    value: "VE",
    phoneCode: "58",
  },
  {
    title: "Vietnam",
    value: "VN",
    phoneCode: "84",
  },
  {
    title: "Western Sahara",
    value: "EH",
    phoneCode: "212",
  },
  {
    title: "Yemen",
    value: "YE",
    phoneCode: "967",
  },
  {
    title: "Zambia",
    value: "ZM",
    phoneCode: "260",
  },
  {
    title: "Zimbabwe",
    value: "ZW",
    phoneCode: "263",
  },
] as const
